import React, { useState, useContext, useEffect } from 'react';
import { IEvent, IMessage } from '../comms';

class SocketController {
    private _socket: WebSocket;
    private _reconnect: () => WebSocket;
    private _eventHandlers: {[key: string]: ((event: IEvent) => void)};

    public get socket(): WebSocket {
        return this._socket;
    }

    public constructor (reconnect: () => WebSocket) {
        this._reconnect = reconnect;

        this._socket = this.openSocket()

        this._eventHandlers = {};
    }

    public addEventHandler<TEvent extends IEvent>(eventName: string, handler: (event: TEvent) => void) {
        this._eventHandlers = { 
            ...this._eventHandlers,
            [eventName]: event => handler(event as TEvent),
        };
    };

    public handleEvent(event: IEvent) {
        this._eventHandlers[event.event] && this._eventHandlers[event.event](event);
    }

    public send<TMessage extends IMessage>(message: TMessage) {
        this._socket.send(JSON.stringify(message));
    }

    private handleMessage = (message: MessageEvent) => {
        const event = JSON.parse(message.data) as IEvent;

        if(!event) {
          alert('Event missing');
          return;
        }

        this.handleEvent(event);
    };

    private openSocket() {
        const socket = this._reconnect();

        socket.onclose = () => {
            window.setTimeout(() => this._socket = this.openSocket(), 100);
        };

        socket.onmessage = this.handleMessage;

        return socket;
    }
}

interface ISocketContext {
    controller?: SocketController;
}

const SocketContext = React.createContext<ISocketContext>({});

interface ISocketContextProviderProps {
    children: React.ReactChild;
}

export const SocketContextProvider = ({ children }: ISocketContextProviderProps) => {
    const [controller, setController] = useState<SocketController>();

    useEffect(() => {
        const connect = () => new WebSocket('wss://jsmwxcelg9.execute-api.eu-west-2.amazonaws.com/production');

        const localController = new SocketController(connect);
        setController(localController);
    }, []);

    return (
        <SocketContext.Provider value={{ controller: controller }}>
            { children }
        </SocketContext.Provider>
    );
}

export const useSocketEventHandler = <TEvent extends IEvent>(eventName: string, handler: (event: TEvent) => void) => {
    const { controller } = useContext(SocketContext);

    controller?.addEventHandler(eventName, handler);
}

export const useSocket = () => useContext(SocketContext).controller;