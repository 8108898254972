import React, { useState } from "react";
import { AppBar, Box, Button, Card, Checkbox, Chip, Container, FormControlLabel, FormGroup, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import { DiceRolledEvent, RollMessage } from "../comms";
import { useSocket, useSocketEventHandler } from "../context";
import Cookies from 'js-cookie';

class DiceRoll {
    private name: string;
    private rolls: Array<number>;
    private time: string;

    constructor(event: DiceRolledEvent) {
        this.name = event.name;
        this.rolls = event.rolls;
        this.time = this.getCurrentTime();
    }

    getName() { return this.name; }
    getRolls(ordered: boolean) { 
        return ordered 
        ? this.rolls.slice().sort((a, b) => b - a) 
        : this.rolls;
    }
    getTime() { return this.time; }

    private getCurrentTime() : string {
        const date = new Date();
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    }
}

export const DiceRollList = () => {

    const socket = useSocket();

    const [rolls, setRolls] = useState<Array<DiceRoll>>([]);

    const [diceCount, setDiceCount] = useState(parseInt(Cookies.get('dice-count') || '2'));
    const [name, setName] = useState(Cookies.get('player-name') || '');
    const [sortRolls, setSortRolls] = useState(Cookies.get('sort-rolls')?.toLowerCase() === 'true');

    const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        Cookies.set('player-name', value);
        setName(value);
    };

    const onDiceCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(30, Math.max(1, parseInt(event.currentTarget.value)));
        Cookies.set('dice-count', value.toString());
        setDiceCount(value);
    };

    const onRollButtonClicked = () => {
        socket?.send(new RollMessage(name, diceCount));
    };

    const onSortChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;
        Cookies.set('sort-rolls', value.toString());
        setSortRolls(value);
    }

    useSocketEventHandler('rolled', (event: DiceRolledEvent) => {
        setRolls([new DiceRoll(event), ...rolls]);
    });

    return (
        <>
            <Box>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            D10 Roller
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container sx={{ mt: '1vh' }}>
                <Card sx={{ p: 2, verticalAlign: 'middle' }}>
                    <Grid container component="form" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required label="Name" value={name} onChange={onNameChanged} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField required label="Dice" type="number" value={diceCount} onChange={onDiceCountChanged} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button variant="contained" sx={{ width: '100%' }} onClick={onRollButtonClicked} disabled={!name}>Roll</Button>
                        </Grid>
                    </Grid>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={sortRolls} onChange={onSortChanged} />} label="Sort rolls" />
                    </FormGroup>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Rolls</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { rolls.map(roll => 
                                    <TableRow>
                                        <TableCell>{roll.getName()}</TableCell>
                                        <TableCell>{roll.getRolls(sortRolls).map(roll => (<Chip label={roll} color={roll === 10 ? "success" : roll === 1 ? "error" : "default"} />))}</TableCell>
                                        <TableCell align="right">{roll.getTime()}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>        
        </>
    );
}