export interface IMessage {
    method: string;
};

export class RollMessage implements IMessage {
    method: string;
    name: string;
    number: number;

    constructor(name: string, number: number) {
        this.method = 'roll';
        this.name = name;
        this.number = number;
    }
};
