import React from 'react';
import { SocketContextProvider } from './context';
import { DiceRollList } from './Components/DiceRollList';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <SocketContextProvider>
        <Routes>
          <Route path="/" element={<DiceRollList />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </SocketContextProvider>
    </div>
  );
}

export default App;
